import React, { useEffect } from 'react';

function checkAccessKey() {
  const parts = window.location.href.split('?');

  const queryPartUrl =
    parts.length > 1 && parts[1] !== 'frame' ? parts[1] : null;
  if (!queryPartUrl) {
    return null;
  }
  let result = {};
  queryPartUrl.split('&').forEach(function (part) {
    let item = part.split('=');
    // @ts-ignore
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
}

// @ts-ignore
function getVkAuthUrl(clientID, redirectUrl) {
  let requestUrl =
    'https://oauth.vk.com/authorize?response_type=code&client_id=' + clientID;
  requestUrl += '&redirect_uri=' + encodeURIComponent(redirectUrl);
  requestUrl += '&scope=public_profile,email,user_birthday,offline';
  requestUrl += '&display=popup';
  return requestUrl;
}

function getCurrentUrl() {
  return window.location.href;
}

// @ts-ignore
export default function VkLogin(props) {
  // @ts-ignore
  const handleMessageFromPopup = (event) => {
    if (event.data.source === 'vk-login') {
      props.onSuccess(event.data.payload);
    }
  };

  const onClick = () => {
    sessionStorage.setItem('vkAutoLoginDisabled', 'false');
    let currentUrl = getCurrentUrl();
    const requestUrl = getVkAuthUrl(props.clientID, currentUrl);

    const h = 650;
    const w = 550;
    if (window.top) {
      const y = window.top.outerHeight / 2 + window.top.screenY - h / 2;
      const x = window.top.outerWidth / 2 + window.top.screenX - w / 2;
      window.open(
        requestUrl,
        'popup',
        `width=${w},height=${h},top=${y},left=${x}`
      );

      window.addEventListener('message', handleMessageFromPopup, {
        once: true,
      });
    }

    window.addEventListener('message', handleMessageFromPopup, {
      once: true,
    });
  };

  const aki = checkAccessKey();
  const receiver = window.parent !== window ? window.parent : window.opener;

  useEffect(() => {
    if (aki && receiver) {
      receiver.postMessage(
        {
          source: 'vk-login',
          payload: aki,
        },
        window.location.origin
      );

      window.close();
    }
  }, [aki, receiver]);

  return <div>{React.cloneElement(props.children, { onClick: onClick })}</div>;
}
