import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

interface Props {
  error?: string;
  label?: string;
  children?: React.ReactNode;
}

function CabinetFormField({ label, error, children }: Props) {
  return (
    <Component>
      {label && <Label>{label}</Label>}
      {children}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Component>
  );
}

const Component = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Label = styled.span`
  display: block;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: ${colors.blue600};
  margin-bottom: 3px;
`;

const ErrorMessage = styled.span`
  position: absolute;
  content: '';
  top: 100%;
  display: block;
  font-size: 11px;
  line-height: 15px;
  color: ${colors.red};

  max-width: calc(100% - 32px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${media.mobile(css`
    max-width: 100%;
  `)}
`;

export default CabinetFormField;
