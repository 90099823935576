import React from 'react';
import styled, { css, keyframes } from 'styled-components';

import { colors } from '@/constants/theme';

type OverlayProps = {
  className?: string;
  children?: React.ReactNode;
};

export function Overlay({ className, children }: OverlayProps) {
  return <OverlayBlock className={className}>{children}</OverlayBlock>;
}

type Props = {
  className?: string;
  absolute?: boolean;
  text?: string;
  isSecondary?: boolean;
  isCabinet?: boolean;
};

function Spinner({
  className,
  absolute = true,
  text,
  isSecondary,
  isCabinet,
}: Props) {
  return (
    <Container
      className={className}
      absolute={absolute}
      isSecondary={isSecondary}
      isCabinet={isCabinet}
    >
      <Circle />
      {text ? <StatusText>{text}</StatusText> : null}
    </Container>
  );
}

const spin = keyframes`
  from {
    transform: rotate(0)
  }
  to {
    transform: rotate(360deg)
  }
`;

const Circle = styled.div`
  display: block;
  width: 25px;
  height: 25px;
  margin: 0 auto;
  border: 2px solid ${colors.white};
  border-top: 3px solid ${colors.main};
  border-radius: 50%;
  animation: ${spin} 1s infinite linear;
`;

const Container = styled.div<{
  absolute?: boolean;
  isSecondary?: boolean;
  isCabinet?: boolean;
}>`
  position: relative;
  z-index: 5;

  ${(props) =>
    props.absolute
      ? css`
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        `
      : ''};

  ${(props) =>
    props.isSecondary &&
    css`
      ${Circle} {
        width: 40px;
        height: 40px;
        border: 2px solid ${colors.main};
        border-top: 3px solid ${colors.white};
      }
    `}

  ${(props) =>
    props.isCabinet &&
    css`
      ${Circle} {
        width: 40px;
        height: 40px;
        border: 2px solid ${colors.main};
        border-top-color: transparent;
      }
    `}
`;

const StatusText = styled.span`
  display: block;
  font-weight: 700;
  margin-top: 10px;
`;

const OverlayBlock = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 16px;
  z-index: 8;
`;

export default Spinner;
