import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';

export const Container = styled.div`
  position: relative;
`;

export const InputStyles = css<{
  withPrefix?: boolean;
  invalid?: boolean;
}>`
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 10px 15px;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.blue600};
  background-color: ${colors.white};
  background-clip: padding-box;
  border: none;
  appearance: none;
  border-radius: 0;
  box-shadow: 0 0 0 1px ${colors.gray400} inset;
  transition: 0.15s, height 0s, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  ${(props) =>
    props.withPrefix
      ? css`
          padding-left: 45px;
        `
      : ''};

  // Placeholder
  &::placeholder {
    color: ${(props) => (props.invalid ? colors.red : '#6c757d')};
    opacity: 1;
  }

  &:hover {
    box-shadow: 0 0 0 1px ${colors.gray400} inset;
    background: rgba(0, 97, 237, 0.03);
  }

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &:disabled {
    background-color: #e9ecef;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1;
  }

  &:not([readonly]):focus {
    outline: 0;
    background: ${colors.white};
    box-shadow: 0 0 0 1px ${colors.main} inset;
  }
`;

export const Input = styled.input<{
  withPrefix?: boolean;
  invalid?: boolean;
}>`
  ${InputStyles}
`;

export const prefixCss = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  pointer-events: none;
`;

export const Prefix = styled.svg`
  ${prefixCss};
`;

export const Badge = styled.div`
  svg {
    position: absolute;
    content: '';
    top: 50%;
    right: 14px;
    transform: translateY(-50%);
    pointer-events: none;
    width: 18px;
    height: 18px;

    path {
      fill: ${colors.gray500};
    }
  }
`;
